export enum Permission {
  partnerAccess = 'partnerAccess',
  adminAccess = 'adminAccess',

  canReadUsers = 'canReadUsers',
  canEditUsers = 'canEditUsers',
  canEditUsersCredentials = 'canEditUsersCredentials',
  canPromoteUsers = 'canPromoteUsers',

  canReadCompanies = 'canReadCompanies',
  canManageCompanies = 'canManageCompanies',

  canGenerateDetailedQuotes = 'canGenerateDetailedQuotes',
  canManageProspects = 'canManageProspects',

  canReadMoves = 'canReadMoves',
  canManageMoves = 'canManageMoves',
  canCustomizeQuotes = 'canCustomizeQuotes',
  canApproveMove = 'canApproveMove',
  canChargeCustomers = 'canChargeCustomers',

  canReadPromoCodes = 'canReadPromoCodes',
  canManagePromoCodes = 'canManagePromoCodes',

  /* partners permissions */

  canManageOwnJobRequests = 'canManageOwnJobRequests',
  canManageOwnJobs = 'canManageOwnJobs',
}
