enum Route {
  Dashboard = '/',
  TermsOfUse = '/terms-of-use',
  PrivacyPolicy = '/privacy-policy',

  SignUp = '/signup',
  EmailVerification = '/email-verification/:token',
  Login = '/login',
  LoginMfa = '/login/mfa/:token',
  LoginError = '/login/error',
  ResetPasswordRequest = '/reset-password',
  ResetPassword = '/reset-password/:token',
  Profile = '/profile',
  ProfileIdentification = '/profile/identification',
  ProfileSecurity = '/profile/security',
  ProfileDeleteAccount = '/profile/delete-account',
  PartnerInvitation = '/invitation/:invitationId',

  Companies = '/companies',
  CompaniesMap = '/companies/map',
  CreateCompany = '/companies/create',
  CompaniesOnBehalf = '/companies/:companyId?',

  EditCompany = '/companies/:companyId?/edit',

  BranchSettingsLandingPage = '/companies/:companyId?/branch/settings',
  BranchAvailabilityLandingPage = '/companies/:companyId?/branch/availability',
  JobRequestsLandingPage = '/companies/:companyId?/branch/job-requests',
  JobsLandingPage = '/companies/:companyId?/branch/jobs',

  Branches = '/companies/:companyId?/branches/:branchId',

  BranchSettings = '/companies/:companyId?/branches/:branchId/settings',
  BranchSettingsLegal = '/companies/:companyId?/branches/:branchId/settings/legal',
  BranchSettingsServices = '/companies/:companyId?/branches/:branchId/settings/services',
  BranchSettingsSeasons = '/companies/:companyId?/branches/:branchId/settings/seasons',
  BranchSettingsLabour = '/companies/:companyId?/branches/:branchId/settings/labour',
  BranchSettingsTripAndTravel = '/companies/:companyId?/branches/:branchId/settings/trip-and-travel',
  BranchSettingsAdditionalCharges = '/companies/:companyId?/branches/:branchId/settings/additional-charges',
  BranchSettingsProtecticeMaterial = '/companies/:companyId?/branches/:branchId/settings/protective-material',
  BranchSettingsCommunication = '/companies/:companyId?/branches/:branchId/settings/communication',
  BranchSettingsCompleted = '/companies/:companyId?/branches/:branchId/settings/completed',

  BranchAvailability = '/companies/:companyId?/branches/:branchId/availability',

  JobRequests = '/companies/:companyId?/branches/:branchId/job-requests',
  JobRequest = '/companies/:companyId?/branches/:branchId/job-requests/:jobRequestId',

  Jobs = '/companies/:companyId?/branches/:branchId/jobs',
  Job = '/companies/:companyId?/branches/:branchId/jobs/:jobId',
  CloseJob = '/companies/:companyId?/branches/:branchId/jobs/:jobId/close',

  AdminUsers = '/users',
  AdminUser = '/users/:userId',

  AdminMoves = '/moves',
  AdminCreateMove = '/moves/create',
  AdminMove = '/moves/:moveId',

  GetQuotes = '/quotes/get',

  Prospects = '/prospects',
  Prospect = '/prospects/:prospectId',
  EditProspect = '/prospects/:prospectId/edit',

  PromoCodes = '/promo-codes',
  CreatePromoCode = '/promo-codes/create',
  EditPromoCode = '/promo-codes/:promoCodeId/edit',
}

export default Route
