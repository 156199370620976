import { useTranslation } from 'react-i18next'

import { type FullMove } from '../../../../../modules/adminMoves'
import PartnerJobBox from './PartnerJobBox'

type PartnerJobsProps = {
  move: FullMove
}

const PartnerJobs: React.FC<PartnerJobsProps> = ({
  move,
}) => {
  const { t } = useTranslation()

  if (!move.jobs.length) {
    return null
  }

  return (
    <div>
      { move.jobs.map(job => (
        <PartnerJobBox
          key={job.id}
          move={move}
          job={job}
        />
      )) }
    </div>
  )
}

export default PartnerJobs
